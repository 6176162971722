import { useContext } from "react";
import WebViewContext from "../../contexts/WebViewContext";
import lockup1_1480 from "../../img/promos/promo-1/lockup-1480.png";
import lockup1_370 from "../../img/promos/promo-1/lockup-370.png";
import lockup1_740 from "../../img/promos/promo-1/lockup-740.png";
import lockup2_1480 from "../../img/promos/promo-2/lockup-1480.png";
import lockup2_370 from "../../img/promos/promo-2/lockup-370.png";
import lockup2_740 from "../../img/promos/promo-2/lockup-740.png";
import lockup3_1480 from "../../img/promos/promo-3/lockup-1480.png";
import lockup3_370 from "../../img/promos/promo-3/lockup-370.png";
import lockup3_740 from "../../img/promos/promo-3/lockup-740.png";
import lockup4_1480 from "../../img/promos/promo-4/lockup-1480.png";
import lockup4_370 from "../../img/promos/promo-4/lockup-370.png";
import lockup4_740 from "../../img/promos/promo-4/lockup-740.png";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getPlayerData } from "../../rtk-core/src/features/player";
import theme from "../../theme";
import { ButtonHyperlink } from "../ButtonLink";
import { ChevronRight } from "../Chevrons";
import { GridItem } from "../Layout";
import RanksExplained from "../RanksExplained";
import ChallengePromo from "./ChallengePromo/ChallengePromo";
import { FooterButton, LeadPromoCopy, PromoCopy } from "./styles";

export const Promo1 = () => {
  const player = useAppSelector(getPlayerData);
  const app = "plw-web";
  const plusers = import.meta.env.VITE_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register/personal?app=${app}&redirect_uri=${redirect}`;
  const { isWebView } = useContext(WebViewContext);

  return (
    <ChallengePromo
      img={
        <img
          alt=""
          srcSet={`${lockup1_370} 370w, ${lockup1_740} 740w, ${lockup1_1480} 1480w`}
          sizes={`(min-width: ${theme.breakpoints[4]}) 730px, calc(100vw - ${theme.space[2]})`}
        />
      }
      title="Fantasy Challenge. The next adventure for Fantasy Premier League."
      flipped={true}
      footerLink={
        !isWebView &&
        !player && (
          <>
            <div>
              <ButtonHyperlink
                href={register}
                $variant="secondary"
                $isFullWidth={true}
              >
                Register to play
                <ChevronRight />
              </ButtonHyperlink>
            </div>
            <div>
              <ButtonHyperlink
                href="#login"
                $variant="tertiary"
                $isFullWidth={true}
              >
                Sign in to play
                <ChevronRight />
              </ButtonHyperlink>
            </div>
          </>
        )
      }
    >
      <LeadPromoCopy id="login">
        Play against your friends in season-long leagues to compete to be the
        best in the world.
      </LeadPromoCopy>
    </ChallengePromo>
  );
};

export const Promo2 = () => (
  <ChallengePromo
    img={
      <img
        alt=""
        srcSet={`${lockup2_370} 370w, ${lockup2_740} 740w, ${lockup2_1480} 1480w`}
        sizes={`(min-width: ${theme.breakpoints[4]}) 730px, calc(100vw - ${theme.space[2]})`}
      />
    }
    title="New week. New challenge."
    footerLink={
      <div>
        <FooterButton to="/help/rules" $variant="tertiary" $isFullWidth={true}>
          Rules in full
          <ChevronRight />
        </FooterButton>
      </div>
    }
  >
    <>
      <PromoCopy>
        Start each Gameweek with a blank team sheet, reset budget and a new
        challenge.
      </PromoCopy>
      <PromoCopy>
        Unlimited Budget? 5 strikers? Points for own-goals? No matter the
        challenge, everyone will be taking it on together.
      </PromoCopy>
    </>
  </ChallengePromo>
);

export const Promo3 = () => (
  <ChallengePromo
    img={
      <img
        alt=""
        srcSet={`${lockup3_370} 370w, ${lockup3_740} 740w, ${lockup3_1480} 1480w`}
        sizes={`(min-width: ${theme.breakpoints[4]}) 730px, calc(100vw - ${theme.space[2]})`}
      />
    }
    title="Ever-changing Events."
    flipped={true}
  >
    <>
      <PromoCopy>
        Compete in themed events that completely take over the game.
      </PromoCopy>
      <PromoCopy>
        Take on your mates in our current International Series event running for
        the next 3 Gameweeks!
      </PromoCopy>
    </>
  </ChallengePromo>
);

export const Promo4 = () => (
  <ChallengePromo
    img={
      <img
        alt=""
        srcSet={`${lockup4_370} 370w, ${lockup4_740} 740w, ${lockup4_1480} 1480w`}
        sizes={`(min-width: ${theme.breakpoints[4]}) 730px, calc(100vw - ${theme.space[2]})`}
      />
    }
    title="Make transfers anytime."
  >
    <>
      <PromoCopy>
        But watch out as players will be locked into your team as soon as their
        match kicks off.
      </PromoCopy>
      <PromoCopy>
        Managers can select up to 5 players from any one club.
      </PromoCopy>
    </>
  </ChallengePromo>
);

const ChallengePromos = () => (
  <>
    <GridItem>
      <Promo1 />
    </GridItem>
    <GridItem>
      <Promo2 />
    </GridItem>
    <GridItem>
      <Promo3 />
    </GridItem>
    <GridItem>
      <Promo4 />
    </GridItem>
    <GridItem>
      <RanksExplained />
    </GridItem>
  </>
);

export default ChallengePromos;
