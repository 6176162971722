import { useEffect, useState } from "react";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getOverallLeagueEntryForEntry } from "../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import {
  getEventPhase,
  getEventsInPhase,
  getMultiEventPhaseForEventId,
} from "../../rtk-core/src/features/phases";
import {
  getPlayerData,
  ILoggedInPlayer,
} from "../../rtk-core/src/features/player";
import { getTopPercentileString } from "../../utils/leagues";
import { getPhaseAssets } from "../../utils/phase";
import EventCarousel from "../EventCarousel";
import PhaseNavigator from "../PhaseNavigator";
import {
  PhaseDescription,
  PhaseEventsTitle,
  PhaseInfo,
  PhaseName,
  PhaseStat,
  PhaseStatHeading,
  PhaseStatMainHeading,
  PhaseStatMainValue,
  PhaseStats,
  PhaseStatsMain,
  PhaseStatsSecondary,
  PhaseStatValue,
  PhaseSummaryDivider,
  PhaseSummaryHeader,
  StyledPhaseSummary,
} from "./styles";

const PhaseSummary = () => {
  const activeEvent = useAppSelector(getActiveEvent);
  // activeEvent should equate to the 'current' event by default
  const currentEventPhase = useAppSelector((state) =>
    getMultiEventPhaseForEventId(state, activeEvent.id)
  );
  const eventsInCurrentEventPhase = useAppSelector((state) =>
    currentEventPhase ? getEventsInPhase(state, currentEventPhase.id) : []
  );

  const nextEventPhase = useAppSelector((state) =>
    currentEventPhase ? getEventPhase(state, currentEventPhase.id + 1) : null
  );

  useEffect(() => {
    // Show the next event when the last Gameweek is finished
    if (
      eventsInCurrentEventPhase[eventsInCurrentEventPhase.length - 1].finished
    ) {
      setActiveEventPhase(nextEventPhase);
    }
  }, [eventsInCurrentEventPhase, nextEventPhase]);

  // activeEventPhase is the current event phase you are looking at. Defaults to the current event phase
  // (got using activeEventId)
  const [activeEventPhase, setActiveEventPhase] = useState(
    currentEventPhase || null
  );

  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  const overallLeagueEntry = useAppSelector((state) =>
    player.entry ? getOverallLeagueEntryForEntry(state, player.entry) : null
  );

  // nextEventPhaseActive is the next event phase relative to the current activeEventPhase
  const nextEventPhaseActive = useAppSelector((state) =>
    activeEventPhase ? getEventPhase(state, activeEventPhase.id + 1) : null
  );

  // prevEventPhaseActive is the previous event phase relative to the current activeEventPhase
  const prevEventPhaseActive = useAppSelector((state) =>
    activeEventPhase ? getEventPhase(state, activeEventPhase.id - 1) : null
  );

  const eventsInActivePhase = useAppSelector((state) =>
    activeEventPhase ? getEventsInPhase(state, activeEventPhase.id) : []
  );

  if (!activeEventPhase || !eventsInActivePhase.length) {
    return null;
  }

  const overallEventPhaseData = overallLeagueEntry?.active_phases.find(
    (p) => p.phase === activeEventPhase.id
  );

  const handleNextClick = () => setActiveEventPhase(nextEventPhaseActive);
  const handlePrevClick = () => setActiveEventPhase(prevEventPhaseActive);

  const phaseAssets = getPhaseAssets(activeEventPhase.id);
  const phaseTitle = phaseAssets?.title || activeEventPhase.name;

  const phaseSubheading = `Gameweek ${activeEventPhase.start_event} - ${activeEventPhase.stop_event}`;
  return (
    <>
      <PhaseNavigator
        activePhaseId={activeEventPhase.id}
        nextOnClick={handleNextClick}
        prevOnClick={handlePrevClick}
      />
      <StyledPhaseSummary>
        {/* Phase header */}
        <PhaseInfo>
          <PhaseSummaryHeader>
            <h2>
              <PhaseEventsTitle>{phaseSubheading}</PhaseEventsTitle>
              <PhaseName>{phaseTitle}</PhaseName>
            </h2>
            <PhaseDescription>{phaseAssets?.description}</PhaseDescription>
          </PhaseSummaryHeader>
          {/* Phase scoreboard */}
          <PhaseStats>
            <PhaseStatsMain>
              <PhaseStatMainHeading>Event pts</PhaseStatMainHeading>
              <PhaseStatMainValue>
                {overallEventPhaseData && overallEventPhaseData.total
                  ? overallEventPhaseData.total
                  : "-"}
              </PhaseStatMainValue>
            </PhaseStatsMain>
            <PhaseStatsSecondary>
              <PhaseStat>
                <PhaseStatHeading>Global rank</PhaseStatHeading>
                <PhaseStatValue>
                  {overallEventPhaseData?.entry_percentile_rank
                    ? getTopPercentileString(
                        overallEventPhaseData.entry_percentile_rank
                      )
                    : "-"}
                </PhaseStatValue>
              </PhaseStat>
              <PhaseStat>
                <PhaseStatHeading>Highest points</PhaseStatHeading>
                <PhaseStatValue>
                  {activeEventPhase.highest_score !== null
                    ? activeEventPhase.highest_score
                    : "-"}
                </PhaseStatValue>
              </PhaseStat>
            </PhaseStatsSecondary>
          </PhaseStats>
        </PhaseInfo>
        <PhaseSummaryDivider />
        <EventCarousel events={eventsInActivePhase} />
      </StyledPhaseSummary>
    </>
  );
};

export default PhaseSummary;
