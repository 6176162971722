import { createSelector } from "@reduxjs/toolkit";
import range from "lodash/range";
import { RootState } from "../../app/store";
import { getCurrentEvent, getEventsById, getFinishedEvents } from "../events";

export const getPhasesById = (state: RootState) => state.phases.byId;

export const getPhases = (state: RootState) =>
  Object.keys(state.phases.byId).map((p) => state.phases.byId[p]);

export const getOverallPhase = createSelector(getPhases, (phases) => {
  return (
    phases.find(
      (phase) => phase.start_event === 1 && phase.stop_event === 38
    ) || null
  );
});

export const getMultiEventPhaseForEventId = createSelector(
  [
    (_: RootState, eventId: number) => eventId,
    (state: RootState, eventId: number) =>
      getPhasesByStartAndStopEventId(state, eventId, eventId)[0],
    getPhases,
    getOverallPhase,
  ],
  (eventId, gameweekPhase, phases, overallPhase) => {
    const excludedIds = [
      gameweekPhase ? gameweekPhase.id : 0,
      overallPhase ? overallPhase.id : 0,
    ];

    return (
      phases.find(
        (phase) =>
          !excludedIds.includes(phase.id) &&
          phase.start_event <= eventId &&
          phase.stop_event >= eventId
      ) || null
    );
  }
);

export const getFinishedPhases = createSelector(
  getPhases,
  getFinishedEvents,
  (phases, events) => {
    if (!events.length) {
      return [];
    }
    const lastFinishedEvent = events[events.length - 1];
    return phases.filter((p) => p.stop_event <= lastFinishedEvent.id);
  }
);

export const getStartedPhases = createSelector(
  getPhases,
  getCurrentEvent,
  (phases, now) => {
    if (!now) {
      return [];
    }
    return phases.filter((p) => p.start_event <= now.id);
  }
);

export const getPhasesByStartAndStopEventId = (
  state: RootState,
  startEventId: number,
  stopEventId: number
) =>
  Object.keys(state.phases.byId)
    .map((key) => state.phases.byId[key])
    .filter(
      (phase) =>
        phase.start_event === startEventId && phase.stop_event === stopEventId
    );

// Get challenge event phase
export const getEventPhase = createSelector(
  [(_: RootState, phaseId: number) => phaseId, getPhasesById],
  (phaseId, phasesById) => {
    const phase = phasesById[phaseId];
    return phase && phase.start_event !== phase.stop_event ? phase : null;
  }
);

// Get events in challenge event phase
export const getEventsInPhase = createSelector(
  [(_: RootState, phaseId: number) => phaseId, getPhasesById, getEventsById],
  (phaseId, phasesById, eventsById) => {
    const phase = phasesById[phaseId];
    return range(phase.start_event, phase.stop_event + 1).map(
      (p) => eventsById[p]
    );
  }
);
